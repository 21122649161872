<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Dripicons Icon</h4>
          </template>
          <template v-slot:body>
            <p>
              Use Class
              <code>&lt;div data-icon="a" class="icon"&gt;&lt;/div&gt;</code>
            </p>
            <b-row class="mt-3">
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="a" class="icon"></div>
                  <span>4k Fill</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="o" class="icon"></div>
                  <span>Up Arrow</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="h" class="icon"></div>
                  <span>Down Arrow</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="j" class="icon"></div>
                  <span>Left Arrow</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="i" class="icon"></div>
                  <span>Right Arrow</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="l" class="icon"></div>
                  <span>left</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="k" class="icon"></div>
                  <span>Down</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="n" class="icon"></div>
                  <span>Up</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="m" class="icon"></div>
                  <span>Right</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="f" class="icon"></div>
                  <span>Anchor</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="g" class="icon"></div>
                  <span>File</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="p" class="icon"></div>
                  <span>Note</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="s" class="icon"></div>
                  <span>Dribbble</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="K" class="icon"></div>
                  <span>Download</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="G" class="icon"></div>
                  <span>Sun</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="H" class="icon"></div>
                  <span>Haf light</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="F" class="icon"></div>
                  <span>Light</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="I" class="icon"></div>
                  <span>Voice</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="x" class="icon"></div>
                  <span>Notification</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="J" class="icon"></div>
                  <span></span>Page</b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="t" class="icon"></div>
                  <span>Battery Empty</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="u" class="icon"></div>
                  <span>Battery Full</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="v" class="icon"></div>
                  <span>Battery One</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="w" class="icon"></div>
                  <span>Battery Two</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="Q" class="icon"></div>
                  <span>Cart</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="P" class="icon"></div>
                  <span>Card</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="O" class="icon"></div>
                  <span>Camera</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="N" class="icon"></div>
                  <span>Video</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="S" class="icon"></div>
                  <span>Check</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="T" class="icon"></div>
                  <span>Angle Down</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="U" class="icon"></div>
                  <span>Angle Left</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="V" class="icon"></div>
                  <span>Angle Right</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="W" class="icon"></div>
                  <span>Angle Up</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="X" class="icon"></div>
                  <span>Up Arrow</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="Y" class="icon"></div>
                  <span>Clock</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="Z" class="icon"></div>
                  <span>Reload</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="0" class="icon"></div>
                  <span>Cloud</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="1" class="icon"></div>
                  <span>Cloud Download</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="2" class="icon"></div>
                  <span>Cloud Upload</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="5" class="icon"></div>
                  <span>Arrow</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="5" class="icon"></div>
                  <span>Arrow</span></b-link
                >
              </b-col>
              <b-col xl="2" lg="3" md="4" sm="6">
                <b-link class="iq-icons-list" href="#"
                  ><div data-icon="5" class="icon"></div>
                  <span>Arrow</span></b-link
                >
              </b-col>
              <b-col sm="12" class="text-center mt-3">
                <!-- <b-link class="btn btn-primary" href="http://demo.amitjakhu.com/dripicons/" target="blank">View All Icon</b-link> -->
                <b-button
                  href="http://demo.amitjakhu.com/dripicons/"
                  target="blank"
                  variant="primary"
                  >View All Icon</b-button
                >
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'
export default {
  name: 'IconDripicons',
  mounted() {
    core.index()
  }
}
</script>
